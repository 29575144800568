import {
    CART_SET,
    CART_INITING,
    CART_REFRESHING,
    CART_EMPTY,
    CART_CREDIT_PAY_SET_ORDER,
    CART_CREDIT_PAY_SET_CARD_LIST,
    CART_CHECKOUT_SET,
    CART_CHECKOUT_REFRESHING,
    CART_CHECKOUT_INITING,
    CART_COLLECT_FILTER,
    CART_COLLECT_PRODUCTS,
    CART_COLLECT_PRODUCTS_APPEND,
    CART_COLLECT_PRODUCTS_SKIP,
    CART_COLLECT_PRODUCTS_LOADING,
    CART_COLLECT_PRODUCTS_FINISHED,
    CART_COUNT,
    CART_GIFT_INFO,
    CART_FILTER_PROMPT_MSG,
    CART_FILTER_SORTS,
    CART_SET_ADDRESSES,
    CART_GIFT_PRODUCTS,
    CART_GIFT_PRODUCTS_APPEND,
    CART_GIFT_PRODUCTS_LOADING,
    CART_GIFT_PRODUCTS_SKIP,
    CART_GIFT_PRODUCTS_FINISHED,
    CART_COUPONS_SET,
    CART_CREDIT_PAY_INITING,
    CART_CHECKOUT_EMPTY,
    CART_CREDIT_PAY_ERROR,
    CART_SET_UNPAID,
    CART_SET_UNPAID_INITING,
    CART_SET_UNPAID_REFRESHING,
    CART_SET_UNPAID_EMPTY,
    CART_SET_TRANSACTION,
    CART_SET_TRANSACTION_LOADING,
    CART_SET_TRANSACTION_REFRESH, 
    CART_SET_TRANSACTION_EMPTY, 
    CART_SET_TRANSACTION_MESSAGES,
    CART_SET_GUIDE_STEP,
    CART_SET_ORDER_CONFIRMED_IS_NEW,
    CART_SET_SELECT_RECOMMEND_PRODUCTS,
    CART_SET_SELECT_RECOMMEND_PRODUCTS_APPEND,
    CART_SET_SELECT_RECOMMEND_PRODUCTS_LOADING,
    CART_SET_SELECT_RECOMMEND_PRODUCTS_FINISHED,
    CART_SET_SELECT_RECOMMEND_PRODUCTS_SKIP,
    CART_SET_PAY_IMAGES_MESSAGE,
    CART_STRIPE_PAY_SET_ORDER,
    CART_STRIPE_PAY_INITING,
    CART_STRIPE_PAY_ERROR,
} from '../../actions/pages/cart'


const initialState = {
    cart: null,
    cartIniting: false,
    cartRefreshing: false,
    cartEmpty: false,
    collectProducts: null,
    collectSkip: 0,
    collectLoading: false,
    collectFinished: false,
    collectFilter: null,
    checkout: null,
    checkoutRefreshing: false,
    checkoutIniting: false,
    checkoutEmpty: false,
    creditOrder: null,
    cardList: null,
    creditIniting: false,
    cartCount: null,
    giftInfo: null,
    filerPromptMsg: null,
    filterSorts: null,
    addresses: null,
    giftProducts: null,
    giftSkip: 0,
    giftLoading: false,
    giftFinished: false,
    coupons: null,
    checkoutEmpty: false,
    creditError: null,
    unpaid: null,
    unpaidIniting: false,
    unpaidRefreshing: false,
    unpaidEmpty: false,
    transaction: null,
    transactionLoading: false,
    transactionRefresh: false,
    transactionEmpty: false,
    transactionMessages: null,
    guideStep: 0,
    isNew: false,
    addOnItems: null,
    addOnSorts: null,
    addOnItemsSkip: 0,
    addOnItemsLoading: false,
    addOnItemsFinished: false,
    selectRecommendProduct: null,
    selectRecommendSkip: 0,
    selectRecommendFinished: false,
    selectRecommendLoading: false,
    payImages: null,
    stripeOrder: null,
    stripeError: null,
    stripeIniting: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CART_SET:
            return { ...state, cart: action.payload }
        case CART_INITING:
            return { ...state, cartIniting: action.payload }
        case CART_REFRESHING:
            return { ...state, cartRefreshing: action.payload }
        case CART_EMPTY:
            return { ...state, cartEmpty: action.payload }

        case CART_COLLECT_FILTER:
            return { ...state, collectFilter: action.payload }
        case CART_COLLECT_PRODUCTS:
            return { ...state, collectProducts: action.payload }
        case CART_COLLECT_PRODUCTS_APPEND:
            return { ...state, collectProducts: [...(state.collectProducts||[]), ...(action.payload||[])] }
        case CART_COLLECT_PRODUCTS_SKIP:
            return { ...state, collectSkip: action.payload }
        case CART_COLLECT_PRODUCTS_LOADING:
            return { ...state, collectLoading: action.payload }
        case CART_COLLECT_PRODUCTS_FINISHED:
            return { ...state, collectFinished: action.payload }

        case CART_CHECKOUT_SET:
            return { ...state, checkout: action.payload }
        case CART_CHECKOUT_REFRESHING:
            return { ...state, checkoutRefreshing: action.payload }
        case CART_CHECKOUT_INITING:
            return { ...state, checkoutIniting: action.payload }
        case CART_CHECKOUT_EMPTY:
            return { ...state, checkoutEmpty: action.payload }    

        case CART_CREDIT_PAY_SET_ORDER:
            return { ...state, creditOrder: action.payload }
        case CART_CREDIT_PAY_SET_CARD_LIST:
            return { ...state, cardList: action.payload }
        case CART_CREDIT_PAY_INITING:
            return { ...state, creditIniting: action.payload }
        case CART_CREDIT_PAY_ERROR:
            return { ...state, creditError: action.payload }    

        case CART_COUNT:
            return { ...state, cartCount: action.payload }
        case CART_GIFT_INFO:
            return { ...state, giftInfo: action.payload }
        case CART_FILTER_PROMPT_MSG:
            return { ...state, filerPromptMsg: action.payload }
        case CART_FILTER_SORTS:
            return { ...state, filterSorts: action.payload }

        case CART_SET_ADDRESSES:
            return { ...state, addresses: action.payload }

        case CART_GIFT_PRODUCTS:
            return { ...state, giftProducts: action.payload }
        case CART_GIFT_PRODUCTS_APPEND:
            return { ...state, giftProducts: [...(state.giftProducts||[]), ...(action.payload||[])] }
        case CART_GIFT_PRODUCTS_LOADING:
            return { ...state, giftLoading: action.payload }
        case CART_GIFT_PRODUCTS_SKIP:
            return { ...state, giftSkip: action.payload }
        case CART_GIFT_PRODUCTS_FINISHED:
            return { ...state, giftFinished: action.payload }
        case CART_COUPONS_SET:
            return { ...state, coupons: action.payload }    

        case CART_SET_UNPAID:
            return { ...state, unpaid: action.payload }    
        case CART_SET_UNPAID_INITING:
            return { ...state, unpaidIniting: action.payload }
        case CART_SET_UNPAID_REFRESHING:
            return { ...state, unpaidRefreshing: action.payload }
        case CART_SET_UNPAID_EMPTY:
            return { ...state, unpaidEmpty: action.payload }


        case CART_SET_TRANSACTION:
            return { ...state, transaction: action.payload }
        case CART_SET_TRANSACTION_LOADING:
            return { ...state, transactionLoading: action.payload }
        case CART_SET_TRANSACTION_REFRESH:
            return { ...state, transactionRefresh: action.payload }
        case CART_SET_TRANSACTION_EMPTY:
            return { ...state, transactionEmpty: action.payload }
        case CART_SET_TRANSACTION_MESSAGES:
            return { ...state, transactionMessages: action.payload }

        
        case CART_SET_GUIDE_STEP:
            return { ...state, guideStep: action.payload }
        case CART_SET_ORDER_CONFIRMED_IS_NEW:
            return { ...state, isNew: action.payload }

        case CART_SET_SELECT_RECOMMEND_PRODUCTS:
            return { ...state, selectRecommendProduct: action.payload }
        case CART_SET_SELECT_RECOMMEND_PRODUCTS_APPEND:
            return { ...state, selectRecommendProduct: action.payload}
        case CART_SET_SELECT_RECOMMEND_PRODUCTS_LOADING:
            return { ...state, selectRecommendLoading: action.payload }
        case CART_SET_SELECT_RECOMMEND_PRODUCTS_FINISHED:
            return { ...state, selectRecommendFinished: action.payload }
        case CART_SET_SELECT_RECOMMEND_PRODUCTS_SKIP:
            return { ...state, selectRecommendSkip: action.payload }
        case CART_SET_PAY_IMAGES_MESSAGE:
            return { ...state, payImages: action.payload }


        case CART_STRIPE_PAY_SET_ORDER:
            return { ...state, stripeOrder: action.payload }
        case CART_STRIPE_PAY_INITING:
            return { ...state, stripeIniting: action.payload }
        case CART_STRIPE_PAY_ERROR:
            return { ...state, stripeError: action.payload }       

        default:
            return state
    }
}