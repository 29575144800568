import {
    fetchShoppingCart,
    openCheckOutOrder,
    openEbanxOrder,
    openStripeOrder,
    initSafeChargeOrder,
    getCardList,
    shoppingcart_select,
    shoppingcart_select_all,
    billingCheckout,
    billingGet,
    billingSetShippingMethod,
    billingSetShippingInsurance,
    billingSetPayMethod,
    filterCollectOrderProducts,
    fetchAddresses,
    filterProductsSorts,
    filterOrderPromptMsg,
    fetchCountCart,
    getGiftsInfo,
    fetchCollectionList,
    fetchCoupons,
    billingSetCoupon,
    shoppingcart_add_to_wish,
    shoppingcart_remove_items,
    shoppingcart_change_qty,
    shoppingcart_change_item,
    shoppingcart_change_address,
    saveBillingAddress,
    setDefaultBillingAddress,
    fetchChangeCurrentCountry,
    billingSetPoints,
    billingSetCash,
    billingUnSetCoupon,
    billingUseCouponCode,
    fetch_unpaid_checkout,
    set_unpaid_checout_method,
    fetch_transaction,
    fetchGetMessage,
    shoppingcart_set_coupon,
    shoppingcart_unset_coupon,
    shoppingcart_apply_coupon_code,
    fetchOrderConfirmIsNew,
    shoppingcart_change_paymethod,
    fetchAddOnItemsFilter,
    fetchSelectRecommend,
    getCountryAppMessage
} from '../../../api'
import Cache from '../../../utils/cache'

import * as PayMethods from '../../../utils/paymethods'

export const CART_SET = 'CART_SET'
export const CART_REFRESHING = 'CART_REFRESHING'
export const CART_INITING = 'CART_INITING'
export const CART_EMPTY = 'CART_EMPTY'

export const CART_COLLECT_FILTER = 'CART_COLLECT_FILTER'
export const CART_COLLECT_PRODUCTS = 'CART_COLLECT_PRODUCTS'
export const CART_COLLECT_PRODUCTS_APPEND = 'CART_COLLECT_PRODUCTS_APPEND'
export const CART_COLLECT_PRODUCTS_SKIP = 'CART_COLLECT_PRODUCTS_SKIP'
export const CART_COLLECT_PRODUCTS_LOADING = 'CART_COLLECT_PRODUCTS_LOADING'
export const CART_COLLECT_PRODUCTS_FINISHED = 'CART_COLLECT_PRODUCTS_FINISHED'

export const CART_SET_ADDRESSES = 'CART_SET_ADDRESSES'

export const CART_CHECKOUT_SET = 'CART_CHECKOUT'
export const CART_CHECKOUT_REFRESHING = 'CART_CHECKOUT_REFRESHING'
export const CART_CHECKOUT_INITING = 'CART_CHECKOUT_INITING'
export const CART_CHECKOUT_EMPTY = 'CART_CHECKOUT_EMPTY'

export const CART_CREDIT_PAY_SET_ORDER = 'CART_CREDIT_PAY_SET_ORDER'
export const CART_CREDIT_PAY_SET_CARD_LIST = 'CART_CREDIT_PAY_SET_CARD_LIST'
export const CART_CREDIT_PAY_INITING = 'CART_CREDIT_PAY_INITING'
export const CART_CREDIT_PAY_ERROR = 'CART_CREDIT_PAY_ERROR'

export const CART_COUNT = 'CART_COUNT'
export const CART_GIFT_INFO = 'CART_GIFT_INFO'
export const CART_FILTER_PROMPT_MSG = 'CART_FILTER_PROMPT_MSG'
export const CART_FILTER_SORTS = 'CART_FILTER_SORTS'

export const CART_GIFT_PRODUCTS = 'CART_GIFT_PRODUCTS'
export const CART_GIFT_PRODUCTS_APPEND = 'CART_GIFT_PRODUCTS_APPEND'
export const CART_GIFT_PRODUCTS_SKIP = 'CART_GIFT_PRODUCTS_SKIP'
export const CART_GIFT_PRODUCTS_LOADING = 'CART_GIFT_PRODUCTS_LOADING'
export const CART_GIFT_PRODUCTS_FINISHED = 'CART_GIFT_PRODUCTS_FINISHED'
export const CART_COUPONS_SET = 'CART_COUPONS_SET'

export const CART_SET_UNPAID = 'CART_SET_UNPAID'
export const CART_SET_UNPAID_INITING = 'CART_SET_UNPAID_INITING'
export const CART_SET_UNPAID_REFRESHING = 'CART_SET_UNPAID_REFRESHING'
export const CART_SET_UNPAID_EMPTY = 'CART_SET_UNPAID_EMPTY'

export const CART_SET_TRANSACTION = 'CART_SET_TRANSACTION'
export const CART_SET_TRANSACTION_LOADING = 'CART_SET_TRANSACTION_LOADING'
export const CART_SET_TRANSACTION_REFRESH = 'CART_SET_TRANSACTION_REFRESH';
export const CART_SET_TRANSACTION_EMPTY = 'CART_SET_TRANSACTION_EMPTY';
export const CART_SET_TRANSACTION_MESSAGES = 'CART_SET_TRANSACTION_MESSAGES'
export const CART_SET_GUIDE_STEP = 'CART_SET_GUIDE_STEP'
export const CART_SET_ORDER_CONFIRMED_IS_NEW = "CART_SET_ORDER_CONFIRMED_IS_NEW";

export const CART_SET_SELECT_RECOMMEND_PRODUCTS = "CART_SET_SELECT_RECOMMEND_PRODUCTS";
export const CART_SET_SELECT_RECOMMEND_PRODUCTS_APPEND = "CART_SET_SELECT_RECOMMEND_PRODUCTS_APPEND";
export const CART_SET_SELECT_RECOMMEND_PRODUCTS_SKIP = "CART_SET_SELECT_RECOMMEND_PRODUCTS_SKIP";
export const CART_SET_SELECT_RECOMMEND_PRODUCTS_FINISHED = "CART_SET_SELECT_RECOMMEND_PRODUCTS_FINISHED";
export const CART_SET_SELECT_RECOMMEND_PRODUCTS_LOADING = "CART_SET_SELECT_RECOMMEND_PRODUCTS_LOADING";
export const CART_SET_PAY_IMAGES_MESSAGE = 'CART_SET_PAY_IMAGES_MESSAGE';

export const CART_STRIPE_PAY_SET_ORDER = 'CART_STRIPE_PAY_SET_ORDER'
export const CART_STRIPE_PAY_INITING = 'CART_STRIPE_PAY_INITING'
export const CART_STRIPE_PAY_ERROR = 'CART_STRIPE_PAY_ERROR'


const isEmptyCart = cart => {
    // 单独有礼物也需要显示
    return !!!cart || (!!!cart.regionalCases || cart.regionalCases.length < 1) && !cart.giftRegionalCase?.gift && !cart.soldoutRegionalCase
}

export const setCart = payload => ({
    type: CART_SET,
    payload
})

export const setCartIniting = payload => ({
    type: CART_INITING,
    payload
})

export const setCartRefreshing = payload => ({
    type: CART_REFRESHING,
    payload
})

export const setCartEmpty = payload => ({
    type: CART_EMPTY,
    payload
})

export const setAddresses = payload => ({
    type: CART_SET_ADDRESSES,
    payload
})

export const setCollectFilter = payload => ({
    type: CART_COLLECT_FILTER,
    payload
})

export const setCollectProducts = payload => ({
    type: CART_COLLECT_PRODUCTS,
    payload
})

export const appendCollectProducts = payload => ({
    type: CART_COLLECT_PRODUCTS_APPEND,
    payload
})

export const setCollectProductsLoading = payload => ({
    type: CART_COLLECT_PRODUCTS_LOADING,
    payload
})

export const setCollectProductsSkip = payload => ({
    type: CART_COLLECT_PRODUCTS_SKIP,
    payload
})

export const setCollectProductsFinished = payload => ({
    type: CART_COLLECT_PRODUCTS_FINISHED,
    payload
})

export const setCheckout = payload => ({
    type: CART_CHECKOUT_SET,
    payload
})

export const setCheckoutResfreshing = payload => ({
    type: CART_CHECKOUT_REFRESHING,
    payload
})

export const setCheckoutIniting = payload => ({
    type: CART_CHECKOUT_INITING,
    payload
})

export const setCheckoutEmpty = payload => ({
    type: CART_CHECKOUT_EMPTY,
    payload
})

export const setCreditOrder = payload => ({
    type: CART_CREDIT_PAY_SET_ORDER,
    payload
})

export const setCardList = payload => ({
    type: CART_CREDIT_PAY_SET_CARD_LIST,
    payload
})

export const setCreditError = payload => ({
    type: CART_CREDIT_PAY_ERROR,
    payload
})

export const setCreditIniting = payload => ({
    type: CART_CREDIT_PAY_INITING,
    payload
})


export const setStripeOrder = payload => ({
    type: CART_STRIPE_PAY_SET_ORDER,
    payload
})

export const setStripeError = payload => ({
    type: CART_STRIPE_PAY_ERROR,
    payload
})

export const setStripeIniting = payload => ({
    type: CART_STRIPE_PAY_INITING,
    payload
})



export const setCartCount = payload => ({
    type: CART_COUNT,
    payload
})

export const setCartGiftInfo = payload => ({
    type: CART_GIFT_INFO,
    payload
})

export const setCartPromptMsg = payload => ({
    type: CART_FILTER_PROMPT_MSG,
    payload
})

export const setCartSorts = payload => ({
    type: CART_FILTER_SORTS,
    payload
})

export const setGiftProduct = payload => ({
    type: CART_GIFT_PRODUCTS,
    payload
})

export const appendGiftProduct = payload => ({
    type: CART_GIFT_PRODUCTS_APPEND,
    payload
})

export const setGiftProductLoading = payload => ({
    type: CART_GIFT_PRODUCTS_LOADING,
    payload
})

export const setGiftProductSkip = payload => ({
    type: CART_GIFT_PRODUCTS_SKIP,
    payload
})

export const setGiftProductFinished = payload => ({
    type: CART_GIFT_PRODUCTS_FINISHED,
    payload
})

export const setCoupons = payload => ({
    type: CART_COUPONS_SET,
    payload
})

export const setUnpaidCheckout = payload => ({
    type: CART_SET_UNPAID,
    payload
})

export const setUnpaidIniting = payload => ({
    type: CART_SET_UNPAID_INITING,
    payload
})

export const setUnpaidRefreshing = payload => ({
    type: CART_SET_UNPAID_REFRESHING,
    payload
})

export const setUnpaidEmpty = payload => ({
    type: CART_SET_UNPAID_EMPTY,
    payload
})

export const setTransaction = payload => ({
    type: CART_SET_TRANSACTION,
    payload
})

export const setTransactionLoading = payload => ({
    type: CART_SET_TRANSACTION_LOADING,
    payload
})

export const setTransactionRefresh = payload =>({
    type: CART_SET_TRANSACTION_REFRESH,
    payload
})

export const setTransactionEmpty = payload =>({
    type: CART_SET_TRANSACTION_EMPTY,
    payload
})

export const setTransactionMessages = payload => ({
    type: CART_SET_TRANSACTION_MESSAGES,
    payload
})

export const setGuideStep = payload => ({
    type: CART_SET_GUIDE_STEP,
    payload
})

export const setCustomerIsNew = payload => ({
    type: CART_SET_ORDER_CONFIRMED_IS_NEW,
    payload
})

export const setSelectRecommendProduct = payload => ({
    type: CART_SET_SELECT_RECOMMEND_PRODUCTS,
    payload
})

export const appendSelectRecommendProduct = payload => ({
    type: CART_SET_SELECT_RECOMMEND_PRODUCTS_APPEND,
    payload
})

export const setSelectRecommendProductSkip = payload => ({
    type: CART_SET_SELECT_RECOMMEND_PRODUCTS_SKIP,
    payload
})

export const setSelectRecommendProductFinished = payload => ({
    type: CART_SET_SELECT_RECOMMEND_PRODUCTS_FINISHED,
    payload
})

export const setSelectRecommendProductLoading = payload => ({
    type: CART_SET_SELECT_RECOMMEND_PRODUCTS_LOADING,
    payload
})

export const setPayImagesMessage = payload =>({
    type: CART_SET_PAY_IMAGES_MESSAGE,
    payload
})

export const action_init_cart = headers => {
    return async dispatch => {
        dispatch(setCartIniting(true))
        dispatch(setCartEmpty(false))
        const response = await fetchShoppingCart(headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartIniting(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
            return response.result
        } else {
            dispatch(setCartEmpty(true))
            dispatch(setCartIniting(false))
            return null
        }

    }
}

export const action_fetch_cart = (cart, headers) => {
    return async dispatch => {
        if (!cart) {
            dispatch(setCartRefreshing(true))
            dispatch(setCartEmpty(false))
            const response = await fetchShoppingCart(headers)
            if (response?.code === 200) {
                dispatch(setCart(response.result))
                dispatch(setCartRefreshing(false))
                dispatch(setCartEmpty(isEmptyCart(response.result)))
                return response.result
            } else {
                dispatch(setCartRefreshing(false))
                dispatch(setCartEmpty(true))
                return null
            }
        } else {
            dispatch(setCart(cart))
            return cart
        }
    }
}

export const action_cart_select = (itemIds, select, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await shoppingcart_select({ itemIds, select }, headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
            return response.result
        } else {
            dispatch(setCartRefreshing(false))
            return null
        }
    }
}

export const action_cart_select_all = (select, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await shoppingcart_select_all(select, headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
            return response.result
        } else {
            dispatch(setCartRefreshing(false))
            return null
        }
    }
}

export const action_cart_add_to_wish = (itemIds, productIds, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await shoppingcart_add_to_wish({ itemIds, productIds }, headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
            return response.result
        } else {
            dispatch(setCartRefreshing(false))
            return null
        }
    }
}

export const action_cart_remove_items = (itemIds, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await shoppingcart_remove_items({ itemIds }, headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
            return response.result
        } else {
            dispatch(setCartRefreshing(false))
            return null
        }
    }
}

export const action_cart_change_qty = (data, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await shoppingcart_change_qty(data, headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
        } else {
            dispatch(setCartRefreshing(false))
        }
        return response
    }
}

export const action_cart_change_item = (data, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await shoppingcart_change_item(data, headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
            //特殊  有地方用到   没有返回response.result
            return response
        } else {
            dispatch(setCartRefreshing(false))
            return null
        }
    }
}

export const action_cart_change_address = (data, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await shoppingcart_change_address(data, headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
            //特殊  有地方用到   没有返回response.result
            return response
        } else {
            dispatch(setCartRefreshing(false))
            return null
        }
    }
}

export const action_cart_set_country = (country, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await fetchChangeCurrentCountry(country, headers)
        if (response?.code === 200) {
            const cartResponse = await fetchShoppingCart(headers)
            if (cartResponse?.code === 200) {
                dispatch(setCart(cartResponse.result))
            }
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(!!!cartResponse.result))
            return cartResponse.result
        } else {
            dispatch(setCartRefreshing(false))
            return null
        }
    }
}


export const action_cart_set_coupon = (couponId, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))

        let response
        if (couponId) {
            response = await shoppingcart_set_coupon(couponId, headers)
        } else {
            response = await shoppingcart_unset_coupon(headers)
        }

        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
        } else {
            dispatch(setCartRefreshing(false))
        }

        return response
    }
}

export const action_cart_use_coupon_code = (code, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await shoppingcart_apply_coupon_code(code, headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
        } else {
            dispatch(setCartRefreshing(false))
        }
        return response
    }
}

export const action_cart_change_paymethod = (payMethod, headers) => {
    return async dispatch => {
        dispatch(setCartRefreshing(true))
        const response = await shoppingcart_change_paymethod(payMethod, headers)
        if (response?.code === 200) {
            dispatch(setCart(response.result))
            dispatch(setCartRefreshing(false))
            dispatch(setCartEmpty(isEmptyCart(response.result)))
        } else {
            dispatch(setCartRefreshing(false))
        }
        return response
    }
}


export const action_cart_checkout = headers => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        dispatch(setCheckoutEmpty(false))
        const response = await billingCheckout(headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
            return response.result
        } else {
            dispatch(setCheckoutResfreshing(false))
            return { error: response?.result || 'Network Error' }
        }
    }
}

export const action_cart_checkout_get = (initing, headers) => {
    return async dispatch => {
        dispatch(setCheckoutEmpty(false))
        if (initing) {
            dispatch(setCheckoutIniting(true))
        } else {
            dispatch(setCheckoutResfreshing(true))
        }
        const response = await billingGet(headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            if (initing) {
                dispatch(setCheckoutIniting(false))
            } else {
                dispatch(setCheckoutResfreshing(false))
            }
            dispatch(setCheckoutEmpty(!response.result))
            return response.result
        } else {
            if (initing) {
                dispatch(setCheckoutIniting(false))
            } else {
                dispatch(setCheckoutResfreshing(false))
            }
            dispatch(setCheckoutEmpty(true))
            return null
        }
    }
}

export const action_cart_checkout_set_shipping_method = (data, headers) => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await billingSetShippingMethod(data, headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
            return response.result
        } else {
            dispatch(setCheckoutResfreshing(false))
            return {
                error: response?.result || 'Network Error',
                errorCode: response?.code
            }
        }
    }
}

export const action_cart_checkout_set_shipping_insurance = (data, headers) => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await billingSetShippingInsurance(data, headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
            return response.result
        } else {
            dispatch(setCheckoutResfreshing(false))
            return {
                error: response?.result || 'Network Error',
                errorCode: response?.code
            }
        }
    }
}

export const action_cart_checkout_set_paymethod = (payMethod, headers) => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await billingSetPayMethod(payMethod, headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
            return response.result
        } else {
            dispatch(setCheckoutResfreshing(false))
            return {
                error: response?.result || 'Network Error',
                errorCode: response?.code
            }
        }
    }
}

export const action_cart_checkout_set_coupon = (couponId, headers) => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await billingSetCoupon(couponId, headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
            return response.result
        } else {
            dispatch(setCheckoutResfreshing(false))
            return {
                error: response?.result || 'Network Error',
                errorCode: response?.code
            }
        }
    }
}

export const action_cart_checkout_set_address = (data, headers) => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await saveBillingAddress(data, headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
        } else {
            dispatch(setCheckoutResfreshing(false))
        }
        return response
    }
}

export const action_cart_checkout_set_default_address = (data, headers) => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await setDefaultBillingAddress(data, headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
            return response.result
        } else {
            dispatch(setCheckoutResfreshing(false))
            return {
                error: response?.result || 'Network Error',
                errorCode: response?.code
            }
        }
    }
}

export const action_cart_checkout_apply_points = (points, headers) => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await billingSetPoints(points, headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
            return response.result
        } else {
            dispatch(setCheckoutResfreshing(false))
            return {
                error: response?.result || 'Network Error',
                errorCode: response?.code
            }
        }
    }
}

export const action_cart_checkout_apply_cash = (cash, headers) => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await billingSetCash(cash, headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
            return response.result
        } else {
            dispatch(setCheckoutResfreshing(false))
            return {
                error: response?.result || 'Network Error',
                errorCode: response?.code
            }
        }
    }
}

export const action_cart_checkout_unset_coupon = headers => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await billingUnSetCoupon(headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
            return response.result
        } else {
            dispatch(setCheckoutResfreshing(false))
            return {
                error: response?.result || 'Network Error',
                errorCode: response?.code
            }
        }
    }
}

export const action_cart_checkout_use_coupon_code = (code, headers) => {
    return async dispatch => {
        dispatch(setCheckoutResfreshing(true))
        const response = await billingUseCouponCode(code, headers)
        if (response?.code === 200) {
            dispatch(setCheckout(response.result))
            dispatch(setCheckoutResfreshing(false))
        } else {
            dispatch(setCheckoutResfreshing(false))
        }
        return response
    }
}

const handleOrder = (orderId, payMethod, headers) => {
    let handleOrder
    switch (payMethod) {
        case PayMethods.__Pay_Method_Stripe_EU__:
        case PayMethods.__Pay_Method_Stripe__:
        case PayMethods.__Pay_Method_Stripe_GB__:
        case PayMethods.__Pay_Method_Stripe_IR__:
            handleOrder = openStripeOrder
            break
        case PayMethods.__Pay_Checkout__:
        case PayMethods.__Pay_Checkout_EU__:
            handleOrder = openCheckOutOrder
            break
        case PayMethods.__Pay_Ebanx__:
        case PayMethods.__Pay_Ebanx_Debet__:
            handleOrder = openEbanxOrder
            break
        case PayMethods.__Pay_Method_SafeCharge__:
            handleOrder = initSafeChargeOrder
            break
        default:
            console.error(`No handler order method, ${payMethod}`)
            break
    }

    if (handleOrder) {
        return handleOrder({ orderId, payMethod }, headers)
    }
}

export const action_fetch_credit_order = (orderId, payMethod, headers) => {
    return async dispatch => {
        dispatch(setCreditError(null))
        const response = await handleOrder(orderId, payMethod, headers)
        if (response?.code === 200) {
            dispatch(setCreditOrder(response.result))
        } else {
            dispatch(setCreditError(response?.result))
        }
        return response
    }
}

export const action_fetch_card_list = (payMethods, headers) => {
    return async dispatch => {
        const response = await getCardList(payMethods?.join?.(','), headers)
        if (response?.code === 200) {
            dispatch(setCardList(response.result))
            return response.result
        } else {
            return null
        }
    }
}


export const action_init_credit_page = (orderId, payMethod, payMethods, headers) => {
    return async dispatch => {
        dispatch(setCreditError(null))
        const promises = [
            handleOrder(orderId, payMethod, headers).then(response => {
                if (response?.code === 200) {
                    return response.result
                }else{
                    dispatch(setCreditError(response?.result))
                }
                return null
            }).catch(r => null),
            getCardList(payMethods?.join?.(','), headers).then(response => {
                if (response?.code === 200) {
                    return response.result
                }
                return null
            }).catch(r => null)
        ]
        dispatch(setCreditIniting(true))
        const values = await Promise.all(promises)
        dispatch(setCreditOrder(values?.[0]))
        dispatch(setCardList(values?.[1]))
        dispatch(setCreditIniting(false))
        return values
    }
}


export const action_add_collect_products = (data, params, headers) => {
    return async dispatch => {
        dispatch(setCollectProductsLoading(true))
        const limit = params?.limit || 20
        const paramsArg = { skip: params?.skip || 0 }
        if (params?.differenceItems > 0) {
            paramsArg['differenceItems'] = params.differenceItems
        } else if (params?.difference > 0) {
            paramsArg['difference'] = params.difference
        }

        const response = await filterCollectOrderProducts(data, { ...paramsArg, type: params?.type, limit, onlyProducts:true }, headers)
        dispatch(setCollectProductsLoading(false))
        if (response?.code === 200) {

            const products = response.result?.map(p => ({
                ...p,
                geekoRequsestId: response.requestId,
                geekoExperimentId: response.experimentId,
                layerName: response.layerName,
                trackingRecords: response.trackingRecords
            }))
            const isFinished = !products || products?.length < 1

            dispatch(appendCollectProducts(products))
            dispatch(setCollectProductsSkip(params.skip + limit))

            if (isFinished) {
                dispatch(setCollectProductsFinished(true))
            }
            return { products: products, skip: params.skip + limit, finished: isFinished }
        } else {
            return null
        }
    }
}

// 切换筛选条件第一次请求
export const action_fetch_collect_products = (data, params, headers) => {
    return async (dispatch, getState) => {
        const { pageCart } = getState()
        dispatch(setCollectProducts(null))
        dispatch(setCollectProductsFinished(false))
        dispatch(setCollectProductsLoading(true))
        dispatch(setCollectFilter(data))
        const limit = params?.limit || 20
        const skip = 0
        const paramsArg = { skip: params?.skip || 0 }
        if (params?.differenceItems > 0) {
            paramsArg['differenceItems'] = params.differenceItems
        } else if (params?.difference > 0) {
            paramsArg['difference'] = params.difference
        }

        const response = await filterCollectOrderProducts(data, { ...paramsArg, type: params?.type, skip, limit, onlyProducts:true}, headers)
        dispatch(setCollectProductsLoading(false))
        if (response?.code === 200) {

            const products = response.result?.map(p => ({
                ...p,
                geekoRequsestId: response.requestId,
                geekoExperimentId: response.experimentId,
                layerName: response.layerName,
                trackingRecords: response.trackingRecords
            }))
            const isFinished = !products || products?.length < 1

            dispatch(setCollectProducts(products))
            dispatch(setCollectProductsSkip(limit))
            if (isFinished) {
                dispatch(setCollectProductsFinished(true))
            }
            return { products: products, skip: skip + limit, finished: isFinished }
        } else {
            return null
        }
    }
}

export const action_collect_products_sort = (item, index)=> {
    return async (dispatch, getState) => {
        const { pageCart } = getState()
        const { collectProducts } = pageCart
        const list1 = collectProducts?.filter(c => c?.id != item?.id)
        list1.splice(index, 0, item)
        dispatch(setCollectProducts(list1))
    }
}


export const action_fetch_addresses = headers => {
    return async dispatch => {
        const response = await fetchAddresses(headers)
        if (response?.code === 200) {
            dispatch(setAddresses(response.result))
            return response.result
        } else {
            return null
        }
    }
}

// export const action_fetch_cart_count = (headers) => {
//     return async dispatch => {
//         const response = await fetchCountCart(headers);
//         if (response?.code === 200) {
//             dispatch(setCartCount(response?.result || null))
//             return response?.result
//         } else {
//             return null
//         }
//     }
// }

export const action_fetch_gift_info = (headers) => {
    return async dispatch => {
        const response = await getGiftsInfo(headers);
        if (response?.code === 200) {
            dispatch(setCartGiftInfo(response?.result || null))
            return response?.result
        } else {
            return null
        }
    }
}

export const action_fetch_order_prompt = (type = 0, headers) => {
    return async dispatch => {
        let progressCouponId = Cache.get("__progress_coupon_id")
        const response = await filterOrderPromptMsg(type, progressCouponId || '', headers)
        if (response?.code === 200) {
            if(!progressCouponId && response?.result?.progressCouponId){
                Cache.set("__progress_coupon_id", response?.result?.progressCouponId, 4*60*60)
            }
            dispatch(setCartPromptMsg(response?.result || null))
            return {...response?.result, progressCouponId: progressCouponId || response?.result?.progressCouponId}
        } else {
            return null
        }
    }
}

export const action_fetch_filter_sorters = (headers) => {
    return async dispatch => {
        const response = await filterProductsSorts(headers)
        if (response?.code === 200) {
            dispatch(setCartSorts(response?.result || null))
            return response?.result
        } else {
            return null
        }
    }
}

export const action_fetch_gifts_list = (id, limit, headers) => {
    return async dispatch => {
        dispatch(setGiftProductFinished(false))
        dispatch(setGiftProductLoading(true))
        const limitArg = limit || 20
        const response = await fetchCollectionList({ collectionId: id }, { skip: 0, limit: limitArg }, headers)
        dispatch(setGiftProductLoading(false))
        if (response?.code === 200) {
            dispatch(setGiftProduct(response?.result || null))
            if (!response?.result || response?.result?.length < 1) {
                dispatch(setGiftProductFinished(true))
            } else {
                dispatch(setGiftProductSkip(limitArg))
            }
            return response?.result
        } else {
            return null
        }
    }
}

export const action_add_gifts_list = (id, limit, headers) => {
    return async (dispatch, getState) => {
        const { pageCart } = getState()
        const limitArg = limit || 20
        dispatch(setGiftProductLoading(true))
        const response = await fetchCollectionList({ collectionId: id }, { skip: pageCart?.giftSkip || 0, limit: limitArg }, headers)
        dispatch(setGiftProductLoading(false))
        if (response?.code === 200) {
            dispatch(appendGiftProduct(response?.result || null))
            if (!response?.result || response?.result?.length < 1) {
                dispatch(setGiftProductFinished(true))
            } else {
                dispatch(setGiftProductSkip(pageCart?.giftSkip + limitArg))
            }
        }
    }
}


export const action_fetch_coupons = headers => {
    return async dispatch => {
        const response = await fetchCoupons(headers)
        if (response?.code === 200) {
            dispatch(setCoupons(response?.result))
            return response?.result
        } else {
            return null
        }
    }
}

export const action_fetch_unpaid_checkout = (orderId, headers) => {
    return async dispatch => {
        dispatch(setUnpaidIniting(true))
        const response = await fetch_unpaid_checkout({orderId}, headers)
        if (response?.code === 200) {
            dispatch(setUnpaidCheckout(response?.result))
            dispatch(setUnpaidIniting(false))
            if(!response?.result){
                dispatch(setUnpaidEmpty(true))
            }else{
                dispatch(setUnpaidEmpty(false))
            }
            return response?.result
        } else {
            dispatch(setUnpaidIniting(false))
            dispatch(setUnpaidEmpty(true))
            return null
        }
    }
}

export const action_refresh_unpaid_checkout = (orderId, headers) => {
    return async dispatch => {
        dispatch(setUnpaidRefreshing(true))
        const response = await fetch_unpaid_checkout({orderId}, headers)
        if (response?.code === 200 && response?.result) {
            dispatch(setUnpaidCheckout({...response?.result, serverTime: response?.serverTime}))
            dispatch(setUnpaidRefreshing(false))
            if(!response?.result){
                dispatch(setUnpaidEmpty(true))
            }else{
                dispatch(setUnpaidEmpty(false))
            }
            return response?.result
        } else {
            dispatch(setUnpaidRefreshing(false))
            dispatch(setUnpaidEmpty(true))
            return null
        }
    }
}

export const action_set_unpaid_method = (params, headers) => {
    return async dispatch => {
        dispatch(setUnpaidRefreshing(true))
        const response = await set_unpaid_checout_method(params, headers)
        if (response?.code === 200 && response?.result) {
            dispatch(setUnpaidCheckout({...response?.result, serverTime: response?.serverTime}))
            dispatch(setUnpaidRefreshing(false))
            if(!response?.result){
                dispatch(setUnpaidEmpty(true))
            }else{
                dispatch(setUnpaidEmpty(false))
            }
            return response?.result
        } else {
            dispatch(setUnpaidRefreshing(false))
            dispatch(setUnpaidEmpty(true))
            return null
        }
    }
}

export const action_fetch_transaction = (params, isRefresh, headers) => {
    return async dispatch => {
        if(isRefresh){
            dispatch(setTransactionRefresh(true));
        }else{
            dispatch(setTransactionLoading(true));
        }
        const response = await fetch_transaction(params, headers)
        if(isRefresh){
            dispatch(setTransactionRefresh(false));
        }else{
            dispatch(setTransactionLoading(false))
        }
        if (response?.code === 200) {
            if(response?.result){
                dispatch(setTransactionEmpty(false));
                dispatch(setTransaction(response?.result))
            }else{
                dispatch(setTransactionEmpty(true));
            }
            return response?.result
        } else {
            return null
        }
    }
}

export const action_fetch_transaction_messages = headers => {
    return async dispatch => {
        const m1186P = fetchGetMessage('M1186', headers).then(data => data?.result).catch(err => null)
        const m1147P = fetchGetMessage('M1147', headers).then(data => data?.result).catch(err => null)
        const m1073P = fetchGetMessage('M1073', headers).then(data => data?.result).catch(err => null)
        const [m1186, m1147, m1073] = await Promise.all([m1186P, m1147P, m1073P])
        const messages = {
            m1186,
            m1147,
            m1073,
        }
        dispatch(setTransactionMessages(messages))
        return messages
    }
}

export const action_fetch_customer_is_new = (buyerId, headers) =>{
    return async dispatch =>{
        const response = await fetchOrderConfirmIsNew(buyerId, headers);
        if(response?.code === 200){
            if(response?.result){
                dispatch(setCustomerIsNew(true));
            }else{
                dispatch(setCustomerIsNew(false));
            }

            return response?.result
        }else{
            return null;
        }
    }
}

export const action_fetch_add_on_filter = (type, headers) => {
    return async dispatch => {
        const response = await fetchAddOnItemsFilter(type, headers)
        if(response?.code == 200 && response?.result){
            dispatch(setCartSorts(response?.result || null))
            return response.result
        } else {
            return null;
        }
    }
}

export const action_append_add_on_items = (data, params, headers) => {
    return async dispatch => {
        dispatch(setCollectProductsLoading(true))
        const limit = params?.limit || 20
        const paramsArg = { skip: params?.skip || 0, type: params?.type || 2}
        if (params?.differenceItems > 0) {
            paramsArg['differenceItems'] = params.differenceItems
        } else if (params?.difference > 0) {
            paramsArg['difference'] = params.difference
        }

        const response = await filterCollectOrderProducts(data, { ...paramsArg, limit, onlyProducts:true }, headers)
        dispatch(setCollectProductsLoading(false))
        if (response?.code === 200) {

            const products = response.result?.map(p => ({
                ...p,
                geekoRequsestId: response.requestId,
                geekoExperimentId: response.experimentId,
                layerName: response.layerName,
                trackingRecords: response.trackingRecords
            }))
            const isFinished = !products || products?.length < 1

            dispatch(appendCollectProducts(products))
            dispatch(setCollectProductsSkip(params.skip + limit))

            if (isFinished) {
                dispatch(setCollectProductsFinished(true))
            }
            return { products: products, skip: params.skip + limit, finished: isFinished }
        } else {
            return null
        }
    }
}

// 切换筛选条件第一次请求
export const action_init_add_on_items = (data, params, headers) => {
    return async (dispatch, getState) => {
        const { pageCart } = getState()
        dispatch(setCollectProducts(null))
        dispatch(setCollectProductsFinished(false))
        dispatch(setCollectProductsLoading(true))
        dispatch(setCollectFilter(data))
        const limit = params?.limit || 20
        const skip = 0
        const paramsArg = { skip: params?.skip || 0, type: params?.type || 2 }
        if (params?.differenceItems > 0) {
            paramsArg['differenceItems'] = params.differenceItems
        } else if (params?.difference > 0) {
            paramsArg['difference'] = params.difference
        }

        const response = await filterCollectOrderProducts(data, { ...paramsArg, skip, limit, onlyProducts:true}, headers)
        dispatch(setCollectProductsLoading(false))
        if (response?.code === 200) {

            const products = response.result?.map(p => ({
                ...p,
                geekoRequsestId: response.requestId,
                geekoExperimentId: response.experimentId,
                layerName: response.layerName,
                trackingRecords: response.trackingRecords
            }))
            const isFinished = !products || products?.length < 1

            dispatch(setCollectProducts(products))
            dispatch(setCollectProductsSkip(limit))
            if (isFinished) {
                dispatch(setCollectProductsFinished(true))
            }
            return { products: products, skip: skip + limit, finished: isFinished }
        } else {
            return null
        }
    }
}

export const action_init_select_recommend_products = (skip, limit = 21, productId, headers) => {
    return async dispatch => {
        dispatch(setSelectRecommendProduct(null))
        dispatch(setSelectRecommendProductSkip(0))
        dispatch(setSelectRecommendProductFinished(false))
        dispatch(setSelectRecommendProductLoading(true))
        const response = await fetchSelectRecommend(0, limit, productId, headers)
        dispatch(setSelectRecommendProductLoading(false))
        if(response?.code == 200){
            const isFinished = response?.result?.length < 1
            if(isFinished){
                dispatch(setSelectRecommendProductFinished(true))
            } else {
                dispatch(setSelectRecommendProduct(response?.result))
                dispatch(setSelectRecommendProductSkip(21))
            }
        } else {
            dispatch(setSelectRecommendProduct(null))
            dispatch(setSelectRecommendProductSkip(0))
        }
    }
}

export const action_append_select_recommend_products = (skip, limit = 21, productId, headers) => {
    return async (dispatch, getState) => {
        const {pageCart} = getState()
        const { selectRecommendProduct, selectRecommendSkip, selectRecommendFinished, selectRecommendLoading } = pageCart
        if(!selectRecommendFinished && !selectRecommendLoading){
            let skipArg = skip || selectRecommendSkip
            dispatch(setSelectRecommendProductLoading(true))
            const response = await fetchSelectRecommend(skipArg, limit, productId, headers)
            dispatch(setSelectRecommendProductLoading(false))
            if(response?.code == 200){
                const isFinished = response?.result?.length < 1
                if(isFinished){
                    dispatch(setSelectRecommendProductFinished(true))
                } else {
                    dispatch(setSelectRecommendProduct([...(selectRecommendProduct || []), ...response?.result]))
                    dispatch(setSelectRecommendProductSkip(skipArg + 21))
                }
            } else {
                dispatch(setSelectRecommendProduct(null))
                dispatch(setSelectRecommendProductSkip(0))
            }
        }
    }
}

export const action_pay_images_message = (headers) =>{
    return async (dispatch) => {
        const response = await getCountryAppMessage("M1819", headers);
        if(response?.code === 200 && response?.result){
            dispatch(setPayImagesMessage(response.result))
            return response?.result
        }else{
            return null;
        }
    }
}

// todo
export const action_init_stripe_payment_page = (orderId, payMethod, headers) => {
    return async dispatch => {
        dispatch(setStripeError(null))
        const promises = [
            openStripeOrder({orderId, payMethod}, headers).then(response => {
                if (response?.code === 200) {
                    return response.result
                }else{
                    dispatch(setStripeError(response?.result))
                }
                return null
            }).catch(r => null),
        ]
        dispatch(setStripeIniting(true))
        const values = await Promise.all(promises)
        dispatch(setStripeOrder(values?.[0]))
        dispatch(setStripeIniting(false))
        return values
    }
}
